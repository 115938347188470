import Vue from 'vue'

const vue = new Vue()

const statusList = () => {
  return {
    
    admin: {
      approval: {
        audit: {
          status: 'waiting_for_payment',
        },
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        head_approve: {
          status: 'audit',
        },
        change_payment_method: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
      revision: {
        new: {
          status: 'check',
        },
        
        canceled: {
          status: 'new',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
      active: {
        audit: {
          status: 'waiting_for_payment',
        },
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        head_approve: {
          status: 'audit',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
      correction: {
        canceled: {
          status: 'new',
        },
        new: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
      close: {
        audit: {
          status: 'waiting_for_payment',
        },
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        head_approve: {
          status: 'audit',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
      hold: {
        audit: {
          status: 'waiting_for_payment',
        },
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        head_approve: {
          status: 'audit',
        },
        add_invoice: {
          status: 'invoice_check'
        },
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      
    },
    
    auditor: {
      approval: {
        audit: {
          status: 'waiting_for_payment',
        },
      },
      active: {
        audit: {
          status: 'waiting_for_payment',
        },
      },
      close: {
        audit: {
          status: 'waiting_for_payment',
        },
      },
      hold: {
        audit: {
          status: 'waiting_for_payment',
        },
      },
    },
    
    buying_control: {
      active: {
        seo_check: {
          status: 'check',
        },
        pr_check: {
          status: 'check',
        },
        buying_approve: {
          status: 'check',
        },
      },
      hold: {
        buying_approve: {
          status: 'check',
        },
        seo_check: {
          status: 'check',
        },
        pr_check: {
          status: 'check',
        },
      },
      close: {
        buying_approve: {
          status: 'check',
        },
        seo_check: {
          status: 'check',
        },
        pr_check: {
          status: 'check',
        },
      },
      approval: {
        buying_approve: {
          status: 'check',
        },
      },
    },
    
    teamlead: {
      
      approval: {
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      revision: {
        canceled: {
          status: 'new',
        },
        new: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      active: {
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      correction: {
        canceled: {
          status: 'new',
        },
        new: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      hold: {
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      close: {
        check: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
    },
    
    manager: {
      
      approval: {
        new: {
          status: 'check',
        },
        change_payment_method: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        add_invoice: {
          status: 'invoice_check',
        },
      },
      
      revision: {
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        add_invoice: {
          status: 'invoice_check'
        },
      },
      
      active: {
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
      },
      
      hold: {
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
      },
      
      close: {
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
      },
      
      correction: {
        canceled: {
          status: 'new',
        },
        new: {
          status: 'check',
        },
        add_invoice: {
          status: 'invoice_check'
        },
      },
      
    },
    
    head_control: {
      
      approval: {
        head_approve: {
          status: 'audit',
        },
        check: {
          status: 'head_approve',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
      },
      
      revision: {
        canceled: {
          status: 'new',
        },
        new: {
          status: 'check',
        },
      },
      
      active: {
        check: {
          status: 'head_approve',
        },
        head_approve: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
      },
      
      hold: {
        check: {
          status: 'head_approve',
        },
        head_approve: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
      },
      
      close: {
        check: {
          status: 'head_approve',
        },
        head_approve: {
          status: 'audit',
        },
        new: {
          status: 'check',
        },
        canceled: {
          status: 'new',
        },
        change_payment_method: {
          status: 'waiting_for_payment',
        },
      },
      
      correction: {
        canceled: {
          status: 'new',
        },
      },
      
    },
    
    chief: {
      approval: {
        final_approve: {
          status: 'waiting_for_payment',
        },
      },
      
    },
    
    finance_manager: {
      approval: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      active: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      correction: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      revision: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      hold: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
      close: {
        invoice_check: {
          status: 'waiting_for_payment',
        },
      },
    },
  }
}

const negativeStatusList = () => {
  return {
    
    admin: {
      
      approval: {
        audit: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        waiting_for_payment: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
      },
      
      revision: {
        audit: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        
        new: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
        
      },
      
      active: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
      },
      
      hold: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
      },
      
      close: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
      },
      
      correction: {
        audit: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        waiting_for_payment: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        invoice_check: {
          status: 'canceled',
        },
      },
      
    },
    
    auditor: {
      approval: {
        audit: {
          status: 'canceled',
        },
      },
      active: {
        audit: {
          status: 'canceled',
        },
      },
      
      hold: {
        audit: {
          status: 'canceled',
        },
      },
      
      close: {
        audit: {
          status: 'canceled',
        },
      },
    },
    
    teamlead: {
      approval: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      revision: {
        new: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      active: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      hold: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      close: {
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      correction: {
        canceled: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
    },
    
    manager: {
      approval: {
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      revision: {
        new: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      correction: {
        new: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      active: {
        new: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      hold: {
        new: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
      close: {
        new: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
        add_invoice: {
          status: 'canceled',
        },
      },
      
    },
    
    buying_control: {
      
      active: {
        pr_check: {
          status: 'canceled',
        },
        seo_check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
      hold: {
        pr_check: {
          status: 'canceled',
        },
        seo_check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
      close: {
        pr_check: {
          status: 'canceled',
        },
        seo_check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
      approval: {
        pr_check: {
          status: 'canceled',
        },
        seo_check: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
    },
    
    head_control: {
      
      approval: {
        check: {
          status: 'canceled',
        },
        head_approve: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
      },
      
      revision: {
        new: {
          status: 'canceled',
        },
      },
      
      active: {
        head_approve: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
      hold: {
        head_approve: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
      close: {
        head_approve: {
          status: 'canceled',
        },
        check: {
          status: 'canceled',
        },
        new: {
          status: 'canceled',
        },
        change_payment_method: {
          status: 'canceled',
        },
        buying_approve: {
          status: 'canceled',
        },
      },
      
    },
    
    chief: {
      approval: {
        final_approve: {
          status: 'canceled',
        },
      },
      
    },
    
    finance_manager: {
      approval: {
        invoice_check: {
          status: 'canceled',
        },
      },
      active: {
        invoice_check: {
          status: 'canceled',
        },
      },
      correction: {
        invoice_check: {
          status: 'canceled',
        },
      },
      revision: {
        invoice_check: {
          status: 'canceled',
        },
      },
      hold: {
        invoice_check: {
          status: 'canceled',
        },
      },
      close: {
        invoice_check: {
          status: 'canceled',
        },
      },
    },
    
  }
}

export const statusForRole = (paymentStatus, deal_status) => {
  const role = vue.$user.get().role
  const currentDealStatus = deal_status
  const currentPaymentStatus = paymentStatus
  const statuses = statusList()
  
  // выводить ли для роли кнопки
  if (role in statuses) {
    
    // какие кнопки выводить для текущего статуса
    if (currentDealStatus in statuses[role]) {
      if (currentPaymentStatus in statuses[role][currentDealStatus]) {
        return statuses[role][currentDealStatus][currentPaymentStatus]
      }
      
    } else {
      return []
    }
    
  } else {
    return []
  }
}

export const negativeStatusForRole = (paymentStatus, deal_status) => {
  const role = vue.$user.get().role
  const currentDealStatus = deal_status
  const currentPaymentStatus = paymentStatus
  const statuses = negativeStatusList()
  
  // выводить ли для роли кнопки
  if (role in statuses) {
    
    // какие кнопки выводить для текущего статуса
    if (currentDealStatus in statuses[role]) {
      
      if (currentPaymentStatus in statuses[role][currentDealStatus]) {
        return statuses[role][currentDealStatus][currentPaymentStatus]
      }
      
    } else {
      return []
    }
    
  } else {
    return []
  }
}

export const rowColor = (payment_type, cost) => {
  let color = ''
  return payment_type === 'postpaid' && cost === 0 ? color = 'blue lighten-4' : color = ''
}

export const reverse = (str) => str.split('-').reverse().join('-')

export const checkAvailableToAction = (roles = []) => {
  const user_role = vue.$user.get().role
  
  return roles.indexOf(user_role) !== -1
}

export const checkAvailableToActionByStatus = (current_status, available_statuses) => {
  const user_role = vue.$user ? vue.$user.get().role : 'admin'
  const statuses_by_role = {
    new: ['admin', 'manager', 'teamlead', 'finance_manager'],
    requisites: ['admin', 'finance_manager'],
    template: ['admin', 'manager', 'teamlead', 'doc_support', 'finance_manager'],
    partner_sign: ['admin', 'manager', 'teamlead', 'finance_manager'],
    payer_sign: ['finance_manager', 'admin'],
    ready: ['admin', 'manager', 'finance_manager', 'teamlead', 'auditor'],
    template_control: ['buying_control', 'finance_manager'],
    review: ['teamlead', 'finance_manager'],
    cancel: ['finance_manager'],
  }
  const check_role = statuses_by_role[current_status].indexOf(user_role) !== -1
  const check_status = available_statuses.indexOf(current_status) !== -1
  
  return check_role && check_status
}

export const prepareTextKeyToDisplay = (text_key) => {
  return String(text_key)
    .replaceAll('-', ' ') // Replace _ with spaces
    .replaceAll('_', ' ') // Replace _ with spaces
    .replace(/\b\w/g, x => x.toUpperCase()) // Make every first letter capitalize
}

export const getTextContrastBasedOnBgColor = (bg_color) => {
  if (bg_color.slice(0, 1) === '#') {
    bg_color = bg_color.slice(1)
  }
  
  if (bg_color.length === 3) {
    bg_color = bg_color.split('').map(function (hex) {
      return hex + hex
    }).join('')
  }
  
  const r = parseInt(bg_color.substr(0, 2), 16)
  const g = parseInt(bg_color.substr(2, 2), 16)
  const b = parseInt(bg_color.substr(4, 2), 16)
  
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  
  return (yiq >= 128) ? 'black' : 'white'
}

export const smoothScrollToTheStartOfThePage = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

export const smoothScrollToTheEndOfThePage = () => {
  document.body.scrollIntoView({
    block: 'end',
    behavior: 'smooth'
  })
}