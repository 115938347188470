import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from './../store'
import access from './../plugins/router-access'
import router_custom from '../router'

const VueCookie = require('vue-cookie')
const vue = new Vue()

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/notifications',
      name: 'Notifications',
      component: () => import('@/components/notifications/Notifications.vue'),
      meta: {
        title: 'Notifications',
        requiresAuth: true,
      },
    },
    {
      path: '/chats',
      name: 'Chats',
      component: () => import('@/components/user_chats/ChatList.vue'),
      meta: {
        title: 'Chats',
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/components/pages/Login.vue'),
      alias: 'login',
      meta: {
        requiresAuth: false,
        name: 'login',
        title: 'Login page',
      },
    },
    {
      path: '/',
      name: 'Main Page',
      component: () => import ('@/components/pages/MainPage.vue'),
      meta: {
        title: 'Main Page',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/components/lg/Dashboard.vue'),
      meta: {
        title: 'Dashboard',
        requiresAuth: true,
      },
    },
    {
      path: '/manual',
      name: 'Manual',
      component: () => import('@/components/pages/Manual.vue'),
      meta: {
        requiresAuth: true,
        title: 'Manual',
      },
    },
    
    {
      path: '/leads/list',
      name: 'Lead List',
      component: () => import('@/components/lg/leads/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Lead list',
        permissions: access.leadList(),
        access: {
          lg: true,
          buying: false,
        }
      },
    },
    {
      path: '/leads/add',
      name: 'Add new lead',
      component: () => import('@/components/lg/leads/Add.vue'),
      alias: 'site_add',
      meta: {
        requiresAuth: true,
        title: 'Lead add',
        access: {
          lg: true,
          buying: false,
        }
      },
    },
    {
      path: '/leads/card/:id',
      name: 'Lead Card',
      component: () => import('@/components/lg/leads/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'Lead Card',
        title: 'Lead Card',
        access: {
          lg: true,
          buying: false,
        }
      },
    },
    {
      path: '/control/list',
      name: 'Control leads list',
      component: () => import('@/components/lg/control/List.vue'),
      alias: 'control',
      meta: {
        requiresAuth: true,
        name: 'Checking leads',
        title: 'Checking leads',
        access: {
          lg: true,
          buying: false,
        }
      },
    },
    
    {
      path: '/users/add',
      name: 'Add new user',
      component: () => import('@/components/service/users/Add.vue'),
      alias: 'user_add',
      meta: {
        requiresAuth: true,
        name: 'Add new user',
        title: 'User Add',
        permissions: access.admins(),
      },
    },
    {
      path: '/users/list',
      name: 'Users List',
      component: () => import('@/components/service/users/List.vue'),
      alias: 'user_list',
      meta: {
        requiresAuth: true,
        name: 'Users List',
        title: 'User List',
        permissions: access.admins(),
      },
    },
    {
      path: '/users/card/:id',
      name: 'User card',
      component: () => import('@/components/service/users/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'user card',
        title: 'User card',
      },
    },
    
    {
      path: '/geoGroups/list',
      name: 'Geo groups',
      component: () => import('@/components/service/geoGroups/List.vue'),
      alias: 'geo_group',
      meta: {
        requiresAuth: true,
        name: 'Geo groups',
        title: 'Geo groups',
        permissions: access.admins(),
      },
    },
    
    {
      path: '/teams/list',
      name: 'Team list',
      component: () => import('@/components/service/teams/List.vue'),
      alias: 'team_list',
      meta: {
        requiresAuth: true,
        name: 'Team list',
        title: 'Teams list',
        permissions: access.admins(),
      },
    },
    {
      path: '/teams/card/:id',
      name: 'Team card',
      component: () => import('@/components/service/teams/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'team card',
        title: 'Team card',
        permissions: access.admins(),
      },
    },
    
    {
      path: '/tags/list',
      name: 'Tags list',
      component: () => import('@/components/service/tags/List.vue'),
      alias: 'tag_list',
      meta: {
        requiresAuth: true,
        name: 'Tags list',
        title: 'Tags list',
        permissions: access.admins(),
      },
    },
    
    // begin partners route
    {
      path: '/partners/:type',
      name: 'Partners list',
      component: () => import ('@/components/buying/partners/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Partners',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    {
      path: '/partners/new/partner',
      name: 'Add new partner',
      component: () => import ('@/components/buying/partners/Add.vue'),
      meta: {
        requiresAuth: true,
        name: 'Add new partner',
        title: 'Add partner',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    {
      path: '/partners/card/:id',
      name: 'Partners card',
      component: () => import ('@/components/buying/partners/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'Partners card',
        title: 'Partners card',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    {
      path: '/partners/card/offline/:id',
      name: 'Partners card offline',
      component: () => import ('@/components/buying/partners/CardOffline.vue'),
      meta: {
        requiresAuth: true,
        name: 'Partners card offline',
        title: 'Partners card offline',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    // deals route
    {
      path: '/deals/add/:type/:id',
      name: 'Add new deals',
      component: () => import ('@/components/buying/deals/Add.vue'),
      meta: {
        requiresAuth: true,
        title: 'New deals',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    {
      path: '/deals/card/:type/:id',
      name: 'Card Deals',
      component: () => import ('@/components/buying/deals/Card.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Card Deals',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    {
      path: '/deals/list',
      name: 'Deals List',
      component: () => import ('@/components/buying/deals/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Deals List',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    // finance
    {
      path: '/finance/list',
      name: 'Finance',
      component: () => import ('@/components/buying/finance/List.vue'),
      // component: List,
      meta: {
        requiresAuth: true,
        title: 'Finance',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    {
      path: '*',
      component: () => import('@/components/pages/NotFound.vue')
      // component: NotFound
    },
    
    //payments
    {
      path: '/payments/list',
      name: 'Payments List',
      component: () => import ('@/components/buying/payments/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Payments List',
        permissions: access.admins(),
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    {
      path: '/black/list',
      name: 'Black list',
      component: () => import ('@/components/service/blackList/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Black list',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    // report
    {
      path: '/general_report',
      name: 'General report',
      component: () => import('@/components/buying/report/Report.vue'),
      meta: {
        title: 'General report',
        requiresAuth: true,
      },
    },
    
    {
      path: '/report',
      name: 'Report',
      component: () => import('@/components/buying/extension_report/Report.vue'),
      meta: {
        title: 'Report',
        requiresAuth: true,
      },
    },
    
    // icons
    {
      path: '/icons',
      name: 'Icons',
      component: () => import('@/components/service/icons/List.vue'),
      meta: {
        title: 'Icons',
        requiresAuth: true,
        permissions: access.adminOnly(),
      },
    },
    
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/components/service/users/Profile.vue'),
      meta: {
        requiresAuth: true,
        name: 'Profile',
        title: 'Profile',
      },
    },
    
    {
      path: '/telegram_auth/:hash',
      name: 'Telegram auth',
      component: () => import('@/components/pages/TelegramAuth.vue'),
      meta: {
        requiresAuth: true,
        name: 'Telegram auth',
        title: 'Telegram auth',
      },
    },
    
    {
      path: '/logs',
      name: 'Logs',
      component: () => import('@/components/service/logs/Logs.vue'),
      meta: {
        requiresAuth: true,
        name: 'Logs',
        title: 'Logs',
      },
    },
    
    {
      path: '/smm/titles',
      name: 'Titles',
      component: () => import('@/components/notifications/tickets_smm/SmmTitles.vue'),
      meta: {
        requiresAuth: true,
        name: 'Titles',
        title: 'Titles',
      },
    },
    
    {
      path: '/tickets',
      name: 'Tickets',
      component: () => import('@/components/notifications/tickets_cpm/TicketsList.vue'),
      meta: {
        requiresAuth: true,
        name: 'Tickets',
        title: 'Tickets',
      },
    },
    
    {
      path: '/tickets/dashboard',
      name: 'Tickets dashboard',
      component: () => import('@/components/notifications/tickets_cpm/dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        name: 'Tickets dashboard',
        title: 'Tickets dashboard',
      },
    },
    
    {
      path: '/seo/keywords',
      name: 'SEO keywords',
      component: () => import('@/components/seo/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        name: 'SEO keywords',
        title: 'SEO keywords',
      },
    },
    
    {
      path: '/seo/domains',
      name: 'SEO domains',
      component: () => import('@/components/seo/Domains.vue'),
      meta: {
        requiresAuth: true,
        name: 'SEO domains',
        title: 'SEO domains',
      },
    },
    
    {
      path: '/brandstealers',
      name: 'Brandstealers',
      component: () => import('@/components/brandstealers/List.vue'),
      meta: {
        requiresAuth: true,
        name: 'Brandstealers',
        title: 'Brandstealers',
      },
    },
    
    {
      path: '/lg/report',
      name: 'Lg report',
      component: () => import('@/components/lg/report/LgReport.vue'),
      meta: {
        requiresAuth: true,
        name: 'Lg report',
        title: 'Lg report',
      },
    },
    {
      path: '/partner/tickets',
      name: 'Partner tickets',
      component: () => import('@/components/notifications/tickets_partner/PartnerTickets.vue'),
      meta: {
        requiresAuth: true,
        name: 'Partner tickets',
        title: 'Partner tickets',
      },
    },
    {
      path: '/articles',
      name: 'Articles',
      component: () => import('@/components/articles/List.vue'),
      meta: {
        requiresAuth: true,
        name: 'Articles',
        title: 'Articles'
      },
    },
    {
      path: '/articles/card/:id',
      name: 'Article card',
      component: () => import ('@/components/articles/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'Article',
        title: 'Article'
      },
    },
    {
      path: '/articles/create',
      name: 'Create article',
      component: () => import ('@/components/articles/Editor.vue'),
      meta: {
        requiresAuth: true,
        name: 'Create article',
        title: 'Create article',
        permissions: access.adminOnly(),
      },
    },
    {
      path: '/articles/update/:id',
      name: 'Update article',
      component: () => import ('@/components/articles/Editor.vue'),
      meta: {
        requiresAuth: true,
        name: 'Update article',
        title: 'Update article',
        permissions: access.adminOnly(),
      },
    },
    {
      path: '/wallet/tips',
      name: 'Wallet tips',
      component: () => import('@/components/service/wallet_tips/List.vue'),
      meta: {
        requiresAuth: true,
        name: 'Wallet tips',
        title: 'Wallet tips',
      },
    },
    {
      path: '/reminders',
      name: 'Reminders',
      component: () => import('@/components/notifications/reminders/Reminders.vue'),
      meta: {
        requiresAuth: true,
        name: 'Reminders',
        title: 'Reminders',
      },
    },
    
    {
      path: '/tickets/affiliate',
      name: 'Tickets Affiliate',
      component: () => import('@/components/buying/affiliate_tickets/List.vue'),
      meta: {
        requiresAuth: true,
        name: 'Tickets affiliate',
        title: 'Tickets affiliate',
      },
    },
    
    {
      path: '/tickets/affiliate/card/:id',
      name: 'Ticket card',
      component: () => import ('@/components/buying/affiliate_tickets/card/Card.vue'),
      meta: {
        requiresAuth: true,
        name: 'Ticket card',
        title: 'Ticket card'
      },
    },
    
    {
      path: '/parser',
      name: 'A-parser',
      component: () => import('@/components/seo/AParser.vue'),
      meta: {
        requiresAuth: true,
        name: 'A-parser',
        title: 'A-parser',
      },
    },
    
    {
      path: '/conditions/list',
      name: 'Agents conditions',
      component: () => import ('@/components/mobicash/conditions/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Agents conditions',
      },
    },
    
    {
      path: '/agents/list',
      name: 'Agents list',
      component: () => import ('@/components/mobicash/agentsList/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Agents list',
        access: {
          agent: true,
        }
      },
    },
    {
      path: '/mobicash/dashboard',
      name: 'Dashboard',
      component: () => import ('@/components/mobicash/dashboard/Dashboard'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
        access: {
          agent: true,
        }
      },
    },
    
    {
      path: '/documentation_new',
      name: 'Documentation',
      component: () => import ('@/components/buying/documentation_new/List.vue'),
      meta: {
        requiresAuth: true,
        title: 'Documentation',
      },
    },
    {
      path: '/documentation/report',
      name: 'Documentation report',
      component: () => import ('@/components/buying/documentation_report/Report.vue'),
      meta: {
        requiresAuth: true,
        title: 'Documentation report',
        access: {
          lg: false,
          buying: true,
        }
      },
    },
    
    {
      path: '/affiliate/report',
      name: 'Affiliate report',
      component: () => import ('@/components/buying/partners_affiliate_report/Report'),
      meta: {
        requiresAuth: true,
        title: 'Affiliate report',
      },
    },
    
    {
      path: '/support',
      name: 'Support',
      component: () => import('@/components/support/SupportTickets.vue'),
      meta: {
        requiresAuth: true,
        title: 'Support',
      },
    },
    
    {
      path: '/support/ticket/:ticketId',
      name: 'Support ticket',
      component: () => import('@/components/support/SupportTicket.vue'),
      meta: {
        requiresAuth: true,
        title: 'Support ticket card',
      },
    },
    {
      path: '/general/settings',
      name: 'General settings',
      component: () => import ('@/components/service/general_settings/GeneralSettings.vue'),
      meta: {
        requiresAuth: true,
        title: 'General settings',
      },
    },
    
    {
      path: '/finplan/report',
      name: 'Financial planning',
      component: () => import ('@/components/buying/fin_plan/table/FinPlanTable'),
      meta: {
        requiresAuth: true,
        title: 'Financial planning',
      },
    },
  
  ]
  
})

import * as ACTIONS from './../store/actions'
import * as EVENTS from './../store/events'

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  
  const token = localStorage.getItem('user-token')
  
  // для страниц, требующих авторизацию, проверяем, авторизован ли юзер
  if (to.meta?.requiresAuth && !token) {
    return next({ path: '/login' })
  }
  
  // авторизованных на страницу логина не пускаем
  if (to.meta.name === 'login' && token) {
    return next({ path: '/' })
  }
  
  // перевіряємо чи є доступ користувача до розділів lg and buying
  store.dispatch(ACTIONS.GET_PROFILE).then(res => {
    const is_admin = res.role === 'admin'
    const need_access = !!to.meta?.access
    
    if (!is_admin && need_access) {
      const denied_lg_access = to.meta.access.lg === true && res.lg === false
      const denied_buying_access = to.meta.access.buying === true && res.buying === false
      
      if (denied_lg_access || denied_buying_access) {
        return next({ path: '/' })
      }
    }
    
    const to_path = to.path
    const from_page = VueCookie.get('fromPage')
    const allowed_paths = ['/profile', '/login', '/logout', '/articles/card/2']
    
    const show_telegram_dialog = from_page === '/login' && !store.getters.profile.telegram_user_id && store.getters.profile.telegram_close_counter < 3
    const need_to_connect_telegram = allowed_paths.indexOf(to_path) && !store.getters.profile.telegram
    const need_to_connect_2fa = allowed_paths.indexOf(to_path) === -1 && !store.getters.profile.two_fa_enabled
    
    if (show_telegram_dialog) {
      setTimeout(() => {
        vue.$eventEmit(EVENTS.SHOW_TELEGRAM_DIALOG)
      }, 1500)
    }
    
    if (need_to_connect_telegram) {
      vue.$notifyWarning({ title: 'Add your Telegram in profile' })
      window.location = '/#/profile'
    }
    
    if (need_to_connect_2fa) {
      vue.$notifyWarning({ title: 'Add 2fa authentication in profile' })
      return router_custom.push('/profile')
    }
  })
  
  if (token) {
    store.dispatch(ACTIONS.GET_VERSION).then(fileVersion => {
      let cookieVersion = parseInt(VueCookie.get('version'))
      
      if (cookieVersion) { // если версия есть - сравниваем с текущей
        if (cookieVersion !== fileVersion) { // если различаются - обновляем куку и перезагружаем страницу
          updateVersion(fileVersion)
        } else {
          axios.defaults.headers.common['path'] = to.path
          next()
        }
      } else { // если версии нет - добавляем куку с версией и перезагружаем
        updateVersion(fileVersion)
      }
    })
  } else {
    next()
  }
  
  if (to.query.hasOwnProperty('nid')) {
    store.dispatch(ACTIONS.READ_NOTIFICATION, {
      notification_id: to.query['nid'],
    }).then(() => {
      vue.$eventEmit(EVENTS.GET_NOTIFICATION)
    })
  }
  
  if (to.query.hasOwnProperty('groupByValue')) {
    this.dashboard.filter_group_by_value = to.query['groupByValue']
  }
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

function updateVersion (fileVersion) {
  VueCookie.set('version', fileVersion, 1)
  location.reload()
  
  // ставим куку для создания уведомления об обновлении
  VueCookie.set('crmUpdate', true, 1)
}

export default router
