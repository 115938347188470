import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    acceptedFilesFormats: [],
    support_administrators: []
};

const getters = {
    acceptedFilesFormats: state => state.acceptedFilesFormats
};

const actions = {
    [ACTIONS.GET_AUDITOR_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.auditorList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_AUDITOR_RESPONSIBLE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.auditorResponsibleId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_AUDITOR_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.updateAuditorResponsible, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ADMIN_SUPPORT_SETTINGS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.get_admin_support_settings)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_ADMIN_SUPPORT_SETTINGS]: ({getters}, payload) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.update_admin_support_settings, payload)
                .then(res => {
                    vue.$notifySuccess({ text: 'Updated' });
                    resolve(res.data)
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_FILE_FORMATS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.getAllChatsExtensions)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_SELECTED_FILE_FORMATS]: ({ commit, getters, state }, payload) => {
        return new Promise((resolve) => {
            if (payload?.getDataFromStoreIfLoaded && state.acceptedFilesFormats.length) {
                resolve(state.acceptedFilesFormats);
                return;
            }

            return axios
                .get(getters.api.settings.getChatsExtensionsSettings)
                .then(res => {
                    commit('setAcceptedFilesFormats', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_FILE_FORMATS_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.updateChatsExtensionSettings, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Updated'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {
    setAcceptedFilesFormats(state, payload) {
        state.acceptedFilesFormats = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
