/**
 * Тут хранятся ссылки на все методы для работы с беком
 */
const state = {
  api: {
    filters: {
      getCountryList: 'filters/countryList',
      getGeoGroupList: 'filters/geoGroupList',
      getSourceTypeList: 'filters/sourceTypeList',
      getSourceCategoryList: 'filters/sourceCategoryList',
      getStatusList: 'filters/statusList',
      getStatusChangeList: 'filters/statusChangeList',
      getManagerList: 'filters/managerList',
      teamManagerList: 'filters/teamManagerList',
      getLogManagerList: 'logs/managerList',
      getRoleList: 'filters/roleList',
      getCostItems: 'filters/costItemsList',
      dealTypes: 'filters/dealTypes',
      userCountriesAccess: 'leads/userCountriesAccess',
      userRoles: 'userRoles',
    },
    
    profile: {
      login: 'profile/login',
      logout: 'profile/logout',
      me: 'profile/me',
      adminLogin: 'profile/adminLogin',
    },
    
    reports: {
      progress: 'reports/progress',
    },
    
    users: {
      users: 'users',
      profile: 'profile',
      userInfoById: 'users/userInfoById',
      listByRole: 'users/listByRole',
      telegram_auth: 'users/telegram_auth',
      teamUsersList: 'users/teamUsersList',
      notifications: 'notifications/sendMessage',
      auto_auth: 'users/auto_auth',
      user_login: 'users/user_login',
      token: 'broadcasting/auth',
      isExists: 'users/telegram/isExists',
      sendCode: 'users/telegram/sendCode',
      update_counter: 'users/telegram/counter',
      validateTelegramCode: 'users/telegram/validateTelegramCode',
    },
    
    security: {
      qr: 'security/generate/code/qr',
      verify: 'security/verify',
      disable: 'security/status/disable',
      disable_2fa: 'security/disable'
    },
    
    version: {
      version: 'version',
    },
    
    teams: {
      teams: 'teams',
      findById: 'teams/findById',
      checkName: 'teams/checkName',
      saveAccess: 'teams/saveAccess',
      getAccess: 'teams/getAccess',
      getTeamUserIds: 'teams/getTeamUserIds',
      getTeamPartnersGroupList: 'teams/availableTeamPartnerGroupList',
      getTeamAvailableUserAutoAssignList: 'teams/availableTeamUserAutoAssignList',
      filters: {
        getCountryList: 'teams/filters/countryList',
        getGeoGroupList: 'teams/filters/geoGroupList',
        getSourceTypeList: 'teams/filters/sourceTypeList',
        getSourceCategoryList: 'teams/filters/sourceCategoryList',
        partnerGroupList: 'teams/filters/partnerGroupList',
      },
    },
    
    partners: {
      partners: 'partners',
      partnerInfoById: 'partners/partnerInfoById',
      contacts: 'partners/contacts',
      sites: {
        sites: 'partners/sites',
        byPartnerId: 'partners/sites/byPartnerId',
        bySiteId: 'partners/sites/bySiteId'
      },
      wallets: {
        wallets: 'partners/wallets',
        partner: 'partners/wallets/partner',
        currencies: 'partners/wallets/currencies',
        types: 'partners/wallets/types',
        types_list: 'wallet/types/list',
        set_tips: 'wallet/types/update'
      },
      groups: 'partners/groups',
      searchSite: 'partners/sites/search',
      search: 'partners/search',
      byProductId: 'partners/byProductId',
      countByProduct: 'partners/count/byProductId',
      offline: 'partners/offline',
      countOffline: 'partners/offline/count',
      typeList: 'partners/typeList',
      tickets_analytic: 'tickets_analytic',
      tickets_analytic_exception: 'tickets_analytic_exception',
      tickets_analytic_by_partner: 'tickets_analytic_by_partner',
      rs_stat: 'partners/rs_stat',
      attach: 'partners/my/attach',
      detach: 'partners/my/detach',
      free: 'partners/free',
      my: 'partners/my',
      group: 'partners/my/groups',
      upload: 'partners/my/upload',
      appoint: 'partners/appoint',
      affiliates: 'partners/affiliates/stats',
      xls: 'partners/affiliates/stats/xls',
      appointed: 'partners/setAppointed',
      takePartners: 'partners/my/upload/takePartners',
      deleteFile: 'partners/appointed/deleteFile',
      allPartners: 'partners/my/attach/allPartners',
    },
    
    products: {
      products: 'products'
    },
    
    macroses: {
      macroses: 'macroses',
    },
    
    redirectors: {
      redirectors: 'redirectors',
    },
    
    paymentTargets: {
      paymentTargets: 'paymentTargets',
      paymentTargetsByManager: 'paymentTargetsByManager'
    },
    
    deals: {
      deals: 'deals',
      offline: 'deals/offline',
      countOffline: 'deals/offline/count',
      placeTypes: 'deals/placeTypes',
      dealPlaces: 'deals/dealPlaces',
      adPlaces: 'deals/adPlaces',
      massDealPlaces: 'deals/massDealPlaces',
      dealPlaceById: 'deals/dealPlaces/ById',
      sites: {
        search: 'deals/sites/search',
        sites: 'sites'
      },
      count: 'deals/count',
      statuses: 'deals/statuses',
      departmentList: 'deals/departmentList',
      updateStatuses: 'deals/updateStatuses',
      uploadDealsFile: 'deals/uploadDealsFile',
      uploadBuyingStatAnalyticFile: 'deals/uploadBuyingStatAnalyticFile',
      deleteDealsFile: 'deals/deleteDealsFile',
      getFileHash: 'deals/getFileHash',
      dealsFincontrol: 'deals/fincontrol',
      dealsFincontrolCount: 'deals/fincontrol/count',
      buyingStat: 'deals/buyingStat',
      history: 'deals/history',
      prolongation: 'deals/prolongation',
      massSpend: 'deals/massSpend',
      spend: 'deals/spend',
      results: 'deals/results',
      adjustment: 'deals/adjustment',
      reportByDeals: 'deals/reportByDeals',
      reportByDealsCharts: 'deals/reportByDeals/charts',
      reportByDealsXls: 'deals/reportByDeals/xls',
      report: 'deals/report',
      extensionTypes: 'deals/extensionTypes',
      spendData: 'deals/spendData',
      labels: 'deals/labels',
      titles: 'deals/smm_titles',
      xls: 'deals/xls',
      attach_lead: 'deals/attach_lead',
      reOpen: 'deals',
      transfer: 'deals/transfer',
      deleteBuyingStatAnalyticFile: 'deals/deleteBuyingStatAnalyticFile',
      client_stat: 'deals/client_stat',
      operation_stats: 'deals/operation_stats',
      site_detach: 'deals/site_detach',
      install_cost: 'deals/install_cost',
      install_for_pay: 'deals/install_for_pay',
      cpi_spend: 'deals/cpi_spend',
      available_payment: 'deals/paymentCreateAllowed',
      entity_statuses: 'admin/entity_statuses',
      change_entity_status: 'admin/change_entity_status',
    },
    
    contracts: {
      contracts: 'deals/contracts',
      statuses: 'deals/contracts/statuses',
      contractsByDeal: 'deals/contractsByDeal',
      contractsByPartner: 'deals/contractsByPartner',
      update: 'deals/contracts/update',
      download: 'deals/contracts/download',
      delete: 'deals/contracts/file',
      delete_contract: 'deals/contracts',
      offline: 'deals/offline/stat_files',
      results: 'deals/offline/result_files',
      formats: 'deals/contracts/formats',
      delete_format: 'deals/contracts/delete',
      changeStatus: 'deals/contracts/changeStatus',
      financialData: 'deals/contracts/financialData',
      files: 'deals/contracts/files/update',
      roleAllowedActions: 'deals/contracts/roleAllowedActions',
      contract_lock: 'deals/contracts/contract_lock',
    },
    
    invoices: {
      invoices: 'invoices',
      count: 'invoices/count',
      changePaymentMethod: 'invoices/dealChangePaymentMethod',
      paymentChangePaymentMethod: 'invoices/changePaymentMethod',
    },
    
    payments: {
      payments: 'payments',
      byDealId: 'payments/byDealId',
      updateStatus: 'payments/updateStatus',
      updateStatuses: 'payments/updateStatuses',
      uploadDealsFile: 'payments/uploadFile',
      deleteDealsFile: 'payments/deleteFile',
      getFileHash: 'payments/getFileHash',
      updateCost: 'payments/updateCost',
      tag: 'payments/tag',
      statuses: 'payments/statuses',
      paymentContract: 'payments/paymentContract',
      forceAudit: 'payments/forceAuditStatus',
      comment: 'payments/comment',
      auditor_lock: 'payments/auditor_lock',
      exchange: 'exchange',
    },
    
    reminders: {
      reminders: 'reminders',
    },
    
    zid: {
      zid: 'deals/zid_cid',
      byDealId: 'deals/zidByDealId',
      findById: 'deals/zid_cid/findById',
      count: 'deals/zidByDealId/count',
      zidPlaces: 'deals/zidPlaces'
    },
    
    retention: {
      brandbandits: 'brandbandits',
      statusList: 'brandbandits/statusList',
      statusFlow: 'brandbandits/statusFlow',
      countryList: 'brandbandits/countryList',
      searchSite: 'brandbandits/searchSite',
      reasonList: 'brandbandits/reasonList',
      massAction: 'brandbandits/updateStatuses',
    },
    
    contacts: {
      createContact: 'contacts/create',
      editContact: 'contacts/update',
      deleteContact: 'contacts/delete',
      getContactById: 'contacts/findById',
      incrementContactAction: 'contacts/incrementContactAction',
    },
    
    dashboards: {
      getHistoryData: 'dashboards/getHistoryData',
      getCurrentData: 'dashboards/getCurrentData',
      getCharts: 'dashboards/getCharts',
      getStatsByStatuses: 'dashboards/getStatsByStatuses',
      userDashboard: 'dashboards/userDashboard',
    },
    
    geoGroups: {
      list: 'geoGroups/list',
      findById: 'geoGroups/findById',
      createGroup: 'geoGroups/create',
      editGroup: 'geoGroups/update',
      checkName: 'geoGroups/checkName',
    },
    
    leads: {
      checkUrl: 'leads/checkUrl',
      checkUrls: 'leads/checkUrls',
      createLead: 'leads/create',
      editLead: 'leads/update',
      editLeadStatus: 'leads/updateStatus', // Для действий контрола
      count: 'leads/count',
      list: 'leads/list',
      listXls: 'leads/list/xls',
      stat_xls: 'leads/stat_xls',
      download: 'leads/download',
      findById: 'leads/findById',
      leadHistory: 'leads/leadHistory',
      massAction: 'leads/massAction',
      upload: 'leads/upload',
      searchForDeals: 'leads/searchForDeals',
      leads: 'leads',
      chart: 'leads/list/chart',
      // mirrors
      mirrors: 'leads/mirrors',
      main: 'leads/mirrors/main',
      mirrors_list: 'leads/mirrors/list',
      report: 'leads/report',
      check_bid: '/leads/bid/ckecklead',
    },
    
    request: {
      delete_image: 'leads/request/image',
      request: 'leads/request',
      transfer: 'leads/transfer',
      cold: 'leads/cold',
      cold_transfer: 'leads/cold/transfer',
    },
    
    logs: {
      getLeadHistory: 'logs/getLeadHistory',
      logs: 'logs',
      actions: 'logs/actions',
      entities: 'logs/entities',
      count: 'logs/count',
    },
    
    tags: {
      tags: 'tags',
      findById: 'tags/findById',
      createTag: 'tags/create',
      editTag: 'tags/update',
      checkName: 'tags/checkName',
    },
    
    blacklists: {
      blacklists: 'blacklists'
    },
    
    icontitles: {
      icontitles: 'icontitles'
    },
    
    communication: {
      comments: 'deals/comments',
      chats: 'chat/byDealId'
    },
    
    tickets: {
      tickets: 'tickets_cpm',
      count: 'tickets_cpm/count',
      compare: 'tickets_cpm/stat',
      dash: 'tickets_cpm/dash',
    },
    
    news: {
      news: 'news',
    },
    
    settings: {
      updateAuditorResponsible: 'settings/general/updateAuditorResponsible',
      auditorResponsibleId: 'settings/general/auditorResponsibleId',
      auditorList: 'settings/general/auditorList',
      
      getAllChatsExtensions: 'settings/general/getAllChatsExtensions',
      updateChatsExtensionSettings: 'settings/general/updateChatsExtensionSettings',
      getChatsExtensionsSettings: 'settings/general/getChatsExtensionsSettings',
      
      get_admin_support_settings: 'settings/general/getAdminSupportSettings',
      update_admin_support_settings: 'settings/general/updateAdminSupportSettings',
    },
    
    notifications: {
      notifications: 'notifications',
      triggers: 'notifications/triggers',
      count: 'notifications/count',
      notificationsRead: 'notifications/read',
      all_read: 'notifications/all_read',
      counters: 'notifications/counters',
    },
    
    chat: {
      createChat: 'chat/createChat',
      sendMessage: 'chat/sendMessage',
      chat: 'chat/chats',
      deleteChat: 'chat/deleteChat',
      readChat: 'chat/readChat',
      unreadChat: 'chat/unreadChat',
      unreadChatsCounter: 'chat/unreadChatsCounter',
      chatMessages: 'chat/chatMessages',
    },
    
    seo: {
      projects: 'seo/projects',
      stat: 'seo/projects/stat',
      position: 'seo/projects/position',
      projectsByUser: 'seo/projectsByUser',
      xls: 'seo/xls',
      competitors: 'seo/competitors',
      competitors_xls: 'seo/competitors/xls',
    },
    
    aparser: {
      preset: 'aparser/preset',
    },
    
    articles: {
      articles: 'articles',
      categories: 'articles/categories',
      uploadImage: 'articles/uploadImage',
    },
    
    agent: {
      agent: 'agent',
      condition: 'agent_condition',
      rejected: 'agent/rejected',
      file: 'agent/file',
      export: 'agent/export',
      dashboard: {
        top_geo: 'agent/dashboard/top-geo',
        count: 'agent/dashboard/count',
        status: 'agent/dashboard/status',
        geo: 'agent/dashboard/geo',
      },
      export_csv: {
        top_geo: 'agent/dashboard/export/top-geo',
        status: 'agent/dashboard/export/status',
        geo: 'agent/dashboard/export/geo',
        count: 'agent/dashboard/export/count',
      }
    },
    
    affiliate: {
      affiliate: 'affiliate',
      list: 'affiliate/list',
      tickets: 'affiliate/tickets',
      triggers: 'affiliate/triggers',
      problems: 'affiliate/tickets/problems',
      file: 'agent/file',
    },
    
    promocodes: {
      promocodes: 'promocodes',
    },
    
    manuals: {
      manuals: 'manuals',
    },
    
    placements: {
      placement_confirmation: 'deals/placement_confirmation',
      placement_place: 'deals/placement_confirmation/place',
      mass_add_place: 'deals/placement_confirmation/place/massAdd',
      payments: 'deals/placement_confirmation/payments',
      upload_screenshot: 'deals/placement_confirmation/place/uploadScreenshots',
      delete_file: 'deals/placement_confirmation/place/deleteFile',
      autodistribution: 'deals/placement_confirmation/spend/autodistribution',
      downloal_file: 'deals/placement_confirmation/place/downloadFile',
      landing_types: 'deals/placement_confirmation/landingTypes'
    },
    
    documentation: {
      dashboards: 'dashboards/documentation',
      payments_general: 'documentation/payments/general',
      payments_average: 'documentation/payments/average',
      contracts: 'documentation/contracts',
    },
    
    support: {
      get_ticket: 'tickets/{ticket_id}',
      get_tickets: 'tickets',
      get_unread_tickets_count: 'tickets/count',
      get_ticket_type_options: 'tickets/lib/types',
      get_ticket_status_options: 'tickets/lib/statuses',
      get_ticket_entity_options: 'tickets/lib/entities',
      get_ticket_role_options: 'tickets/lib/roles',
      get_available_tags: 'tickets/tags',
      create_new_ticket: 'tickets',
      send_message_to_ticket: 'tickets/message/{ticket_id}',
      update_ticket: 'tickets/{ticket_id}',
      change_ticket_status: 'tickets/status/{ticket_id}',
      wrong_role: 'tickets/{ticket_id}/wrong_role',
      mark_tickets_as_read: 'tickets/mark_as_read',
      mark_tickets_as_unread: 'tickets/mark_as_unread',
      create_new_tag: 'tickets/tags',
      edit_available_tag: 'tickets/tags',
      attach_tag_to_ticket: 'tickets/{ticket_id}/attach',
      detach_tag_from_ticket: 'tickets/{ticket_id}/detach'
    },
    
    finplan: {
      finplan: 'finplan',
      string: 'finplan/string',
      data: 'finplan/strings/data',
      update_string: 'finplan/string/data',
      csv: 'finplan/string/csv',
      deal: 'deal/finplan'
    },
  },
}

const getters = {
  api: state => state.api,
}

const actions = {}

export default {
  state,
  getters,
  actions,
}
