// lead card submit
export const LEAD_CART_SUBMIT = 'lead.cart.submit';
export const LEAD_CART_CONTACT_VALIDATION = 'lead.cart.contact.validation';
export const LEAD_CARD_CONTACTS_SUBMIT = 'lead.card.contacts.submit';
export const CHANGE_LEAD_STATUS = 'change.lead.status';

// partner card submit
export const PARTNER_CREATED = 'partner.created';
export const PARTNER_COMPONENTS_CREATED = 'partner.components.created';
export const PARTNER_CARD_SUBMIT = 'partner.card.submit';
export const PARTNER_FORM_SUBMIT = 'partner.form.submit';
export const PARTNER_CARD_CONTACT_VALIDATION = 'partner.card.contact.validation';
export const PARTNER_CARD_CONTACTS_SUBMIT = 'partner.card.contacts.submit';
export const GET_PARTNER_TICKETS = 'get.partner.tickets';
export const GET_PARTNER_STATS = 'get.partner.stats';
export const GET_PARTNER_INFO = 'get.partner.info';
export const GET_PARTNER_LIST = 'get.partner.list';

// deals submit
export const WALLET_CREATED = 'wallet.created';
export const WALLET_DIALOG_CLOSE = 'wallet.created.close';

export const BID_CREATED = 'bid.created';
export const BID_DIALOG_CLOSE = 'bid.created.close';

export const BID_KZ_CREATED = 'bid.kz.created';
export const BID_KZ_DIALOG_CLOSE = 'bid.kz.created.close';

export const UPDATE_INVOICE_LIST = 'update.invoice.list';
export const UPDATE_DEALS_LIST = 'update.deals.list';

export const DEAL_CARD_ANALYTICS_SUBMIT = 'deal.card.analytics.submit';
export const GET_DEAL_PLACES = 'get.deal.places';
export const AVAILABLE_PAYMENT = 'available.payment';

export const DEAL_CARD_ANALYTICS_VALIDATE = 'deal.card.analytics.validate';
export const DEAL_ANALYTICS_DELETE = 'deal.analytics.delete';

export const DEAL_CHANGE_STATUS = 'deal.change.status';
export const DEAL_UPDATE = 'deal.update';

export const MACROS_CREATED = 'macros.created';
export const MACROS_DIALOG_CLOSE = 'macros.created.close';

export const REDIRECTOR_CREATED = 'redirector.created';
export const REDIRECTOR_DIALOG_CLOSE = 'redirector.created.close';

export const CHANGED_SPEND = 'changed.spend';
export const CREATE_NEW_PAYMENT = 'create.new.payment';
export const UPDATE_DEAL_PLACES = 'update.deal.places';
export const GET_DEAL_INFO = 'get.deal.info';
export const UPDATE_DEAL_PAYMENTS = 'update.deal.payments';
export const APPROVE_CURRENT_PAYMENT = 'approve.current.payment';


export const OPEN_MOBILE_MENU = 'open.mobile.menu';
export const UPDATE_DEAL_ZIDS = 'update.deal.zids';
export const CREATE_NEW_ADJUSTMENTS = 'create.new.adjustments';
export const TO_BLACK_LIST = 'to.black.list';
export const GET_CRM_PAYMENTS = 'get.crm.payments';
export const BLACK_LIST_DIALOG_CLOSE = 'black.list.dialog.close';
export const BLACK_LIST_DIALOG_CLOSE_CPM = 'black.list.dialog.close.cpm';
export const ADDED_TO_BLACK_LIST = 'added.to.black.list';
export const ADDED_TO_BLACK_LIST_CPM = 'added.to.black.list.cpm';

export const ADDED_TO_ICON_LIST = 'added.to.icon.list';
export const ICON_DIALOG_CLOSE = 'icon.dialog.close';
export const GET_NOTIFICATION = 'get.notification';
export const UPDATE_DEAL_COUNT = 'update.deal.count';
export const VALIDATE_DEAL = 'validate.deal';

export const DIALOG_CLOSE = 'dialog.close';
export const CREATE_DEAL_PLACES = 'create.deal.places';

export const CLOSE_DIALOG = 'close.dialog';
export const CREATE_NEW_ANALYTICS = 'create.new.analytics';
export const CREATE_OFFLINE_ANALYTICS = 'create.offline.analytics';
export const CLEAR_ANALYTICS_FORM = 'clear.analytics.form';

export const LEAD_CREATED = 'lead.created';
export const ADDED_NEW_LEAD = 'addwd.new.lead';

export const GET_RETENTION_LIST = 'get.retention.list';

export const ADDED_LEAD_TO_DEALS = 'added.lead.to.deals';
export const ADDED_PLACE_TO_DEALS = 'added.place.to.deals';

export const GET_DEAL_CONTRACTS = 'get.deal.contracts';
export const GET_CONTRACTS = 'get.contracts';
export const UPDATE_LIST = 'update.list';
export const CHANGE_BUYING_LINK = 'change.buying.link';
export const GET_LEAD_INFO = 'get.lead.info';
export const GET_DEAL_ANALYTICS = 'get.deal.analytics';
export const CHECK_ACCESS_TO_CREATE_PAYMENT = 'check.access.to.create.payment';
export const GET_DEAL_PLACES_ANALYTICS = 'get.deal.places.analytics';
export const CLOSE_UPLOAD_DIALOG = 'close.upload.dialog';
export const GET_PAYMENTS = 'get.payments';
export const GET_DEAL_PAYMENTS = 'get.deal.payments';
export const SET_AVAILABLE_TO_PAY = 'set.available.to.pay';
export const GET_DEAL_INSTALLS = 'get.deal.installs';
export const GET_USER_REMINDERS = 'get.user.reminders';
export const SHOW_2FA_DIALOG = 'show.2fa.dialog';
export const DISABLED_RS_BUTTON = 'disabled.rs.button';

export const GET_PAYMENTS_COUNTERS = 'get.payments.counters';
export const GET_AGENTS_LIST = 'get.agents.list';
export const GET_AGENTS_COUNTERS = 'get.agents.counters';
export const GET_AGENTS_FILTERED_COUNTERS = 'get.agents.filtered.counters';
export const GET_CHART_FILTERS = 'get.chart.filters';

export const ADD_STATS_COMMENT = 'add.stats.comment';
export const GET_AFFILIATE_TICKETS_COUNTERS = 'get.affiliate.tickets.counters';
export const GET_AFFILIATE_TICKETS_LIST = 'get.affiliate.tickets.list';
export const GET_TICKET_INFO = 'get.ticket.info';
export const DISABLED_LOADER = 'disabled.loader';

export const UPDATE_BUYING_DEAL_COUNT = 'update.buying.deal.count';
export const UPDATE_BUYING_DEAL_SEO_COUNT = 'update.buying.deal.seo.count';
export const UPDATE_BUYING_DEAL_PR_COUNT = 'update.buying.deal.pr.count';

export const UPDATE_BUYING_PAYMENT_COUNT = 'update.buying.payment.count';
export const GET_ACTIVE_TAB = 'get.active.tab';

export const GET_DEAL_RESULT = 'get.deal.result';
export const DISABLE_RESULT_LOADER = 'disable.result.;oader';
export const SELECT_SEO_DATES = 'select.seo.dates';

export const GET_COUNTERS = 'get.counters';
export const ADD_ERROR_TO_SEARCH_INPUT = 'add.error.to.search.input';
export const GET_PLACEMENTS_PAYMENTS = 'get.placements.payments';
export const FINAL_APPROVE_COUNTER = 'final.approve.counter';

export const GET_TAGS_LIST = 'get.tags.list';
export const CLOSE_TAG_MODAL = 'close.tag.modal';
export const GET_SELECTED_FILTERS = 'get.selected.filters';
export const GET_SELECTED_FILTERS_MOBICASH = 'get.selected.filters.mobicash';
export const GET_ACTIVE_TAB_MOBICASH = 'get.active.tab.mobicash';

export const GET_SELECTED_MANAGERS = 'get.selected.managers';
export const FILTER_AGENT_STATUSES_BY_MANAGER = 'filter.agent.statuses.by.manager';
export const FILTERS_MOBICASH_LOADER = 'filters.mobicash.loader';

export const GET_PAGINATION_SORT = 'get.pagination.sort';
export const FILTERS_LOADER = 'filters.loader';
export const GET_NEW_MESSAGE_BY_CHAT_ID = 'get.new.message.by.chat.id';
export const GET_NEW_MESSAGES = 'get.new.messages';
export const GET_NEW_MESSAGES_TOOLBAR = 'get.new.messages.toolbar';
export const CLOSE_REQUEST_DIALOG = 'close.request.dialog';
export const GET_NEW_REQUESTS = 'get.new.request';
export const GET_DEAL_PLACEMENTS = 'get.deal.placements';
export const CLEAR_PAYMENTS_ANALYTICS = 'clear.analytics.form';
export const SHOW_TELEGRAM_DIALOG = 'show.telegram.dialog';
export const GET_REQUEST_COUNTERS = 'get.request.counters';
export const SHOW_WARNING_DIALOG = 'show.warning.dialog';

// support
export const UPDATE_SUPPORT_TICKETS_LIST = 'update.support.tickets.list';
export const UPDATE_SUPPORT_TICKET_CARD = 'update.support.ticket.card';
export const ADD_LEAD_TO_DEAL = 'add.lead.to.deal';

// finplan
export const CLEAR_FIN_PLAN_PERIOD_DATA = 'clear.fin.plan.period.data';
export const GET_FIN_PLAN_BY_DEAL = 'get.fin.plan.by.deal';
export const GET_PAYMENTS_LIST_FOR_FINPLAN = 'get.payment.list.for.fin.plan.by';
export const IS_NEED_FINPLAN_FOR_DEAL = 'is.need.fin.plan.for.deal';
export const EXPAND_FINPLAN_SECTION = 'expand.finplan.section';
export const SCROLL_TO_FINPLAN_SECTION = 'scroll.to.finplan.section';
