import Vue from 'vue'
import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()

const FingerprintPlugin = {
  install (Vue, options) {
    Vue.prototype.$detectUser = async () => {
      try {
        const fp = await fpPromise
        const result = await fp.get()

        axios.defaults.headers.common['fingerprint'] = result.visitorId
      } catch (error) {
        console.error(error)
      }
    }
  }
}

Vue.use(FingerprintPlugin)
