import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VuetifyConfirm from 'vuetify-confirm'
import * as filters from './plugins/filters'
import _ from 'lodash'
import VueSanitize from 'vue-sanitize'

// Plugins
import './plugins'
import * as ACTIONS from './store/actions'

Object.keys(filters).forEach(name => Vue.filter(name, filters[name]))
Vue.config.productionTip = false
Vue.use(VuetifyConfirm, {
  buttonFalseText: 'Oh, no',
  buttonTrueText: 'Yes',
  width: 400,
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
})

const defaultOptions = {
  allowedTags: ['a', 'p', 'img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'strong', 'iframe'],
  allowedAttributes: {
    'a': ['href', 'target', 'class'],
    'img': ['src', 'class'],
    'p': ['class'],
    'li': ['class'],
    'iframe': ['src', 'class', 'frameborder', 'allowfullscreen'],
  }
}
Vue.use(VueSanitize, defaultOptions)
var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)
Vue.use(_)

window.io = require('socket.io-client')

Vue.prototype.$detectUser().then(() => {
  new Vue({
    render: h => h(App),
    store,
    router,
    beforeCreate: function () {
      this.$store.dispatch(ACTIONS.GET_ICONS_LIST)
      this.$store.dispatch(ACTIONS.GET_MANUALS)
      // проверяем наличие токена при перезагрузке страницы
      this.$store.dispatch(ACTIONS.GET_PROFILE).then(res => {
        this.$subscribe(res.id)

      })
    },
  }).$mount('#app')

})



