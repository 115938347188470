import Vue from 'vue'
import axios from 'axios'

import * as ACTIONS from '../actions'
import { prepareTextKeyToDisplay } from '@/plugins/helpers'

const vue = new Vue()

const state = {
  supportUnreadTicketsCount: 0,
  supportTicketTypeOptions: [],
  supportTicketStatusOptions: [],
  supportTicketEntityOptions: [],
  supportTicketRoleOptions: undefined,
  supportTicketAvailableTags: [],
}

const getters = {
  supportUnreadTicketsCount: state => state.supportUnreadTicketsCount,
  supportTicketAvailableTags: state => state.supportTicketAvailableTags,
  
  supportTicketTypeOptions: state => {
    return state.supportTicketTypeOptions.map(type => {
      return {
        text: prepareTextKeyToDisplay(type),
        value: type
      }
    })
  },
  supportTicketStatusOptions: state => {
    return state.supportTicketStatusOptions.map(status => {
      return {
        text: prepareTextKeyToDisplay(status),
        value: status
      }
    })
  },
  supportTicketEntityOptions: state => {
    return state.supportTicketEntityOptions.map(entity => {
      return {
        text: prepareTextKeyToDisplay(entity),
        value: entity
      }
    })
  },
  supportTicketRoleOptions: state => {
    const roles = state.supportTicketRoleOptions
    
    if (roles) {
      return Object.keys(roles).map(key => {
        return {
          text: prepareTextKeyToDisplay(key),
          value: roles[key],
          default: key === 'admin'
        }
      })
    } else {
      return []
    }
  },
  supportTicketPriorityOptions: () => {
    return ['Low', 'Medium', 'High']
  },
  
  supportTicketTypeColors: () => {
    return {
      'Bug': '#ff5d5d',
      'Question': '#0095ff',
      'Suggestion': '#e9b400',
      'other': '#808080'
    }
  },
  supportTicketStatusColors: () => {
    return {
      'open': '#0095ff',
      'at work': '#ff8d00',
      'closed': '#ff5d5d',
      'other': '#808080'
    }
  }
}

const actions = {
  [ACTIONS.GET_SUPPORT_TICKET_TYPE_OPTIONS]: async ({ commit, getters, state }, payload) => {
    if (payload?.getDataFromStoreIfLoaded && state.supportTicketTypeOptions.length) {
      return
    }
    
    await axios
      .get(getters.api.support.get_ticket_type_options)
      .then(response => {
        commit('setSupportTicketTypeOptions', response.data.data)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_TICKET_STATUS_OPTIONS]: async ({ commit, getters, state }, payload) => {
    if (payload?.getDataFromStoreIfLoaded && state.supportTicketStatusOptions.length) {
      return
    }
    
    await axios
      .get(getters.api.support.get_ticket_status_options)
      .then(response => {
        commit('setSupportTicketStatusOptions', response.data.data)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_TICKET_ENTITY_OPTIONS]: async ({ commit, getters, state }, payload) => {
    if (payload?.getDataFromStoreIfLoaded && state.supportTicketEntityOptions.length) {
      return
    }
    
    await axios
      .get(getters.api.support.get_ticket_entity_options)
      .then(response => {
        commit('setSupportTicketEntityOptions', response.data.data)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_TICKET_ROLE_OPTIONS]: async ({ commit, getters, state }, payload) => {
    if (payload?.getDataFromStoreIfLoaded && state.supportTicketRoleOptions) {
      return
    }
    
    await axios
      .get(getters.api.support.get_ticket_role_options)
      .then(response => {
        commit('setSupportTicketRoleOptions', response.data.data)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  
  [ACTIONS.GET_SUPPORT_TICKET]: async ({ getters }, params) => {
    const query_url = getters.api.support.get_ticket.replace('{ticket_id}', params.ticket_id)
    
    return await axios
      .get(query_url, { params })
      .catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_TICKETS]: async ({ getters }, params) => {
    return await axios
      .get(getters.api.support.get_tickets, { params })
      .catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_UNREAD_TICKETS_COUNT]: async ({ commit, getters }) => {
    await axios
      .get(getters.api.support.get_unread_tickets_count)
      .then(response => {
        commit('setSupportUnreadTicketsCount', response.data.data.count)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.GET_SUPPORT_TICKET_AVAILABLE_TAGS]: async ({ commit, getters }) => {
    await axios
      .get(getters.api.support.get_available_tags)
      .then(response => {
        commit('setSupportTicketAvailableTags', response.data.data)
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  
  [ACTIONS.CREATE_SUPPORT_TICKET]: async ({ getters, state }, payload) => {
    if (payload.params instanceof FormData) {
      payload.params.append('status', 'open')
    } else {
      payload.params.status = 'open'
    }
    
    await axios
      .post(getters.api.support.create_new_ticket, payload.params)
      .then(() => {
        vue.$notifySuccess({ text: 'Ticket successfully created' })
        payload?.successCallback()
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.UPDATE_SUPPORT_TICKET]: async ({ getters, state }, payload) => {
    const request_url = getters.api.support.update_ticket.replace('{ticket_id}', payload.ticket_id)
    
    await axios
      .put(request_url, payload)
      .then(() => {
        vue.$notifySuccess({ text: 'Ticket successfully updated' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.CHANGE_SUPPORT_TICKET_STATUS]: async ({ getters, state }, payload) => {
    const request_url = getters.api.support.change_ticket_status.replace('{ticket_id}', payload.ticket_id)
    
    await axios
      .post(request_url, payload)
      .then(() => {
        vue.$notifySuccess({ text: 'Ticket successfully updated' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.SUPPORT_TICKET_WRONG_ROLE]: async ({ getters, state }, payload) => {
    const request_url = getters.api.support.wrong_role.replace('{ticket_id}', payload.ticket_id)
    
    await axios
      .post(request_url, payload)
      .then(() => {
        vue.$notifySuccess({ text: 'You are no longer responsible for this ticket' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.SEND_MESSAGE_TO_SUPPORT_TICKET]: async ({ getters, state }, payload) => {
    const request_url = getters.api.support.send_message_to_ticket.replace('{ticket_id}', payload.ticket_id)
    
    await axios
      .post(request_url, payload.formData)
      .catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.MARK_SUPPORT_TICKETS_AS_READ]: async ({ getters, state, dispatch }, payload) => {
    await axios
      .put(getters.api.support.mark_tickets_as_read, payload)
      .then(async () => {
        await dispatch(ACTIONS.GET_SUPPORT_UNREAD_TICKETS_COUNT)
        
        if (!payload?.disableSuccessAlert) {
          vue.$notifySuccess({
            text: payload.ticket_ids?.length > 1 ? 'Tickets marked as read' : 'Ticket marked as read'
          })
        }
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.MARK_SUPPORT_TICKETS_AS_UNREAD]: async ({ getters, state, dispatch }, payload) => {
    await axios
      .put(getters.api.support.mark_tickets_as_unread, payload)
      .then(async () => {
        await dispatch(ACTIONS.GET_SUPPORT_UNREAD_TICKETS_COUNT)
        
        vue.$notifySuccess({
          text: payload.ticket_ids?.length > 1 ? 'Tickets marked as unread' : 'Ticket marked as unread'
        })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  
  [ACTIONS.CREATE_SUPPORT_TAG]: async ({ getters, state }, payload) => {
    await axios
      .post(getters.api.support.create_new_tag, payload)
      .then(() => {
        vue.$notifySuccess({ text: 'Tag successfully created' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.UPDATE_SUPPORT_TAG]: async ({ getters, state }, payload) => {
    await axios
      .put(`${getters.api.support.edit_available_tag}/${payload.id}`, {
        color: payload.color,
        name: payload.name
      })
      .then(() => {
        vue.$notifySuccess({ text: 'Tag successfully updated' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.ATTACH_SUPPORT_TAG_TO_TICKET]: async ({ getters, state }, payload) => {
    const request_url = getters.api.support.attach_tag_to_ticket.replace('{ticket_id}', payload.ticket_id)
    
    await axios
      .put(request_url, {
        tag_id: payload.tag_id
      })
      .then(() => {
        vue.$notifySuccess({ text: 'Tag successfully attached' })
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
  [ACTIONS.DETACH_SUPPORT_TAG_FROM_TICKET]: async ({ getters, state }, payload) => {
    if (!confirm('Are you sure you want to detach this tag?')) {
      return
    }
    
    const request_url = getters.api.support.detach_tag_from_ticket.replace('{ticket_id}', payload.params.ticket_id)
    
    await axios
      .put(request_url, {
        tag_id: payload.params.tag_id
      })
      .then(() => {
        vue.$notifySuccess({ text: 'Tag successfully detached' })
        payload?.successCallback()
      }).catch(error => {
        vue.$catchProceed(error)
      })
  },
}

const mutations = {
  setSupportUnreadTicketsCount (state, payload) {
    state.supportUnreadTicketsCount = payload
  },
  setSupportTicketTypeOptions (state, payload) {
    state.supportTicketTypeOptions = payload
  },
  setSupportTicketStatusOptions (state, payload) {
    state.supportTicketStatusOptions = payload
  },
  setSupportTicketEntityOptions (state, payload) {
    state.supportTicketEntityOptions = payload
  },
  setSupportTicketRoleOptions (state, payload) {
    state.supportTicketRoleOptions = payload
  },
  setSupportTicketAvailableTags (state, payload) {
    state.supportTicketAvailableTags = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
